/**
 * LazyLoadVideoController
 *
 * This Stimulus controller provides lazy loading functionality for video and iframe elements.
 * When the media enters the viewport (i.e., becomes visible to the user), this controller
 * sets the `src` attribute of the media using its `data-src` attribute, initiating the media load.
 *
 * Usage:
 *
 * To use this controller, add the necessary `data` attributes to your video or iframe tags.
 * Specifically, the `data-controller`, `data-src`, `data-lazy-load-video-target` or `data-lazy-load-iframe-target` attributes
 * are required. Here's an example of how to define a video element that uses this controller:
 *
 * ```html
 * <video
 *    autoplay="autoplay"
 *    loop="loop"
 *    muted="muted"
 *    playsinline="playsinline"
 *    preload="none"
 *    data-controller="lazy-load-video"
 *    data-lazy-load-video-target="video"
 *    data-src="URL_TO_YOUR_VIDEO.mp4">
 * </video>
 * ```
 * 
 * Or an iframe:
 * <iframe
 *    width="560"
 *    height="315"
 *    data-controller="lazy-load-video"
 *    data-lazy-load-video-target="iframe"
 *    data-src="URL_TO_THE_IFRAME_CONTENT"
 *    frameborder="0"
 *    allowfullscreen>
 * </iframe>
 *
 * Ensure that the Stimulus controller file (`lazy_load_video_controller.js`) is correctly imported
 * and initialized in your JavaScript bundle.
 *
 * Note: Make sure the `data-src` attribute contains the actual URL of the video or iframe you want to lazy load.
 *
 * Dependencies:
 * This controller relies on the IntersectionObserver API to detect when videos enter the viewport.
 * Ensure compatibility with the target browsers or consider using a polyfill.
 */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['video', 'iframe']

  connect() {
    this.loadMediaWhenInView()
  }

  loadMediaWhenInView() {
    if ('IntersectionObserver' in window) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }

      this.observer = new IntersectionObserver(this.handleIntersect.bind(this), options)

      this.videoTargets.forEach(video => this.observer.observe(video))
      this.iframeTargets.forEach(iframe => this.observer.observe(iframe))
    }
  }

  handleIntersect(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (entry.target.matches('[data-lazy-load-video-target="video"]')) {
          this.loadAndPlayVideo(entry.target)
        } else if (entry.target.matches('[data-lazy-load-video-target="iframe"]')) {
          this.loadIframe(entry.target)
        }
        observer.unobserve(entry.target)
      }
    })
  }

  loadAndPlayVideo(videoElement) {
    videoElement.src = videoElement.getAttribute('data-src')
    videoElement.load()
    videoElement.play()
  }

  loadIframe(iframeElement) {
    iframeElement.src = iframeElement.getAttribute('data-src')
  }
}
